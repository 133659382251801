import React from "react";
import PropTypes from "prop-types";
import {Snackbar, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
class SnackBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getProps = this.getProps.bind(this);
  }
  getProps() {
    var myProps = this.props;
    var data = {
      open: myProps.open === undefined ? false : myProps.open,
      message: myProps.message === undefined ? "" : myProps.message,
      onClose: myProps.onClose === undefined ? () => null : myProps.onClose,
    };
    return data;
  }
  render() {
    let props = this.getProps();
    return ( <Snackbar open={props.open} autoHideDuration={6000}
        onClose={()=>{props.onClose()}} anchorOrigin={{vertical:"bottom",horizontal:"left"}}>
        <Alert onClose={()=>{props.onClose()}} severity="success"
          elevation={6} variant="filled" >
          <Typography>{props.message}</Typography>
        </Alert>
      </Snackbar>
    );
  }
}

SnackBarComponent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

SnackBarComponent.defaultProps = {
  open: false,
  onClose: () => null,
};

export default SnackBarComponent;