import React from "react";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { changePassword } from "../../../Service/auth";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: "",
            currentPasswordError: "",
            newPassword: "",
            newPasswordError: "",
            confirmPassword: "",
            confirmPasswordError: ""
        };
        this.changePassword = this.changePassword.bind(this);
    }
    changePassword() {
        var me = this;
        me.setState({ currentPasswordError: "", newPasswordError: "", confirmPasswordError: "" });
        const currentPassword = me.state.currentPassword;
        if (currentPassword == null || currentPassword.trim().length === 0) {
            me.setState({ currentPasswordError: "Must not be empty." });
            return;
        }
        const newPassword = me.state.newPassword;
        if (newPassword == null || newPassword.trim().length === 0) {
            me.setState({ newPasswordError: "Must not be empty." });
            return;
        }
        const confirmPassword = me.state.confirmPassword;
        if (confirmPassword == null || confirmPassword.trim().length === 0) {
            me.setState({ confirmPasswordError: "Must not be empty." });
            return;
        }
        const postData = {
            currentPassword: currentPassword,
            newPassword: newPassword
        }
        changePassword(postData).then(() => {
            window.sessionStorage.removeItem("authToken");
            window.sessionStorage.removeItem("user_email");
            this.props.history.push("/");
        }).catch((er) => {
            console.log(er)
        })
    }

    render() {
        console.log(this.state)
        return (
            <div className="container-fluid">
                <div className="w-50 m-auto shadow p-3">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" component={RouterLink} to='/home' >
                            Home
                        </Link>
                        <Typography color="textPrimary">Change Password</Typography>
                    </Breadcrumbs>
                    <h4>Change Password</h4>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Current Password
                        </label>
                        <input
                            id="name"
                            className="form-control"
                            type="password"
                            value={this.state.currentPassword}
                            onChange={(event) => {
                                this.setState({ currentPassword: event.target.value ,currentPasswordError:""});
                            }}
                        />
                        {this.state.currentPasswordError.length !== 0 && (
                            <h6 className="text-danger">{this.state.currentPasswordError}</h6>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            New Password
                        </label>
                        <input
                            id="name"
                            className="form-control"
                            type="password"
                            value={this.state.newPassword}
                            onChange={(event) => {
                                this.setState({ newPassword: event.target.value ,newPasswordError:""});
                            }}
                        />
                        {this.state.newPasswordError.length !== 0 && (
                            <h6 className="text-danger">{this.state.newPasswordError}</h6>
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                            Confirm Password
                        </label>
                        <input
                            id="name"
                            className="form-control"
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={(event) => {
                                this.setState({ confirmPassword: event.target.value, confirmPasswordError:"" });
                            }}
                        />
                        {this.state.confirmPasswordError.length !== 0 && (
                            <h6 className="text-danger">{this.state.confirmPasswordError}</h6>
                        )}
                    </div>
                    <div style={{ textAlign: "end" }}>
                        <button
                            type="button"
                            className="btn btn-secondary mr-2"
                            onClick={() => {
                                this.props.history.push("/home")
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                this.changePassword();
                            }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ChangePassword);
