import React from "react";
import PropTypes from "prop-types";
import {Dialog,DialogContent,CircularProgress} from '@material-ui/core';
class LoaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getProps = this.getProps.bind(this);
  }
  getProps() {
    var myProps = this.props;
    var data = {
      open: myProps.open === undefined ? false : myProps.open,
    };
    return data;
  }
  render() {
    let props = this.getProps();
    return ( <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogContent>
        <CircularProgress color="secondary"  />
        </DialogContent>
    </Dialog>
    );
  }
}

LoaderComponent.propTypes = {
    open: PropTypes.bool,
};

LoaderComponent.defaultProps = {
  open: false,
};

export default LoaderComponent;