import axios from "axios";
import * as q from "q";
const url = process.env.REACT_APP_SERVER;
export function auth(data) {
  var defer = q.defer();
  axios
    .post(url + "/auth", data)
    .then(function (response) {
      const response_data = response.data;
      if (response.data !== undefined) {
        window.sessionStorage.setItem("authToken", response_data.token);
        defer.resolve(response_data);
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    })
    .catch(function (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.message !== undefined
      ) {
        defer.reject({ error: error.response.data.message });
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    });
  return defer.promise;
}

export function getUser() {
  var defer = q.defer();
  const authToken = window.sessionStorage.getItem("authToken", null);
  axios.get(url + "/user", {
    headers: {
        Authorization: "Bearer " + authToken,
    },
    }).then(function (response) {
      const response_data = response.data;
      if (response.data !== undefined) {
        window.sessionStorage.setItem("user_email", response_data.email);
        defer.resolve(response_data);
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    })
    .catch(function (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.message !== undefined
      ) {
        defer.reject({ error: error.response.data.message });
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    });
  return defer.promise;
}

export function changePassword(data) {
    var defer = q.defer();
    const authToken = window.sessionStorage.getItem("authToken", null);
    axios
      .put(url + "/user", data,{
        headers: {
            Authorization: "Bearer " + authToken,
        },
    
    }).then(function (response) {
        const response_data = response.data;
        if (response.data !== undefined) {
          defer.resolve(response_data);
        } else {
          defer.reject({ error: "Something went wrong!" });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response !== undefined &&
          error.response.data !== undefined &&
          error.response.data.message !== undefined
        ) {
          defer.reject({ error: error.response.data.message });
        } else {
          defer.reject({ error: "Something went wrong!" });
        }
      });
    return defer.promise;
  }
