import {TableCell} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



export default withStyles((theme) => {

  return {
    root: {
      border: "1px solid rgba(224, 224, 224, 1)",
      padding:"8px",
      color:"inherit",
      '& .MuiTableCell-head':{
        color:"blue"
      }
    },
  }
})(TableCell);