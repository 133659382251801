import React, { forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from "react-router-dom";
import * as moment from "moment";
import MaterialTable from "material-table"
import {
  AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn
} from '@material-ui/icons/';
import { FormControlLabel, TableHead, TableRow, Switch, Typography, Grid } from "@material-ui/core";
import { getData } from "../../../Service/tara";
import "./style.css";
import CustomDatePicker from "../../Common/DatePicker";
import { displayOrder } from "../../../Util/productSubs";
import LoaderComponent from "../../Common/Loader";
import TableCell from "../../Common/TableCell";
import TaraTableEdit from "../TaraTableEdit";
import ModalComponent from "../../Common/Modal";


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
class TaraTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taraData: [],
      showData: [],
      currentData: [],
      error: null,
      loading: false,
      showExpired: false,
      filterStartDate: new Date(),
      filterEndDate: new Date(),
      editItem: null,
      isEdit:false,
      confirmModal:false
    };
    this.handleExpired = this.handleExpired.bind(this);
    this.filterData = this.filterData.bind(this);
    this.convertData = this.convertData.bind(this);
    this.checkLevels = this.checkLevels.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.editComponent = React.createRef();
  }
  componentDidMount() {
    var user = sessionStorage.getItem("authToken");
    if (user === undefined || user === null) {
      this.props.history.push("/");
      return;
    }
    this.fetchData();
  }

  // fetching the db data
  fetchData() {
    this.setState({ loading: true })
    getData()
      .then((res) => {
        var _items = res.Items;
        if (_items.length !== 0) {
          _items.forEach((item) => {
            item["is_edit"] = false;
            item["role_error"] = false;
            item["name_error"] = false;
            if (item.person === undefined) {
              item["person"] = [];
            }
            return item;
          });
          for (let i = 0; i < _items.length; i++) {
            let user = [];
            //check if all level are present or not 
            _items[i].subscription = this.checkLevels(_items[i].subscription)
            for (let j = 0; j < _items[i].subscription.length; j++) {
              if (_items[i].subscription[j].license === undefined) {
                _items[i].subscription[j]["license"] = {
                  create: {
                    author: "",
                    date: "",
                    is_created: false,
                  },
                  update: {
                    author: "",
                    date: "",
                  },
                };
              }
              user.push(_items[i].subscription[j]);
            }
            _items[i].subscription = user;

            // sort the backend data
            _items[i].subscription.sort(function (a, b) {
              return (
                displayOrder.indexOf(a.product) - displayOrder.indexOf(b.product)
              );
            });
          }
          _items.sort(function (a, b) {
            return new Date(b.register_time_stamp) - new Date(a.register_time_stamp);
          });
          // convert data to show in the table and for exporting
          this.convertData(_items)
          this.setState(
            {
              taraData: _items,
              currentData: _items,
              loading: false,
            },
          );
        }
      })
      .catch((er) => {
        console.log(er);
      });
  }
  // function to check all levels are present in the subscription if not then add
  checkLevels(subData) {
    if (subData !== undefined && subData.length > 0) {
      for (let i = 0; i < displayOrder.length; i++) {
        if (!subData.some((el) => { return el.product === displayOrder[i] })) {
          if (displayOrder[i] === "5th") {
            let temp = {
              "product": displayOrder[i],
              "is_active": false,
              "subject": "evs",
              "expiry_date": 0
            }
            subData.push(temp);
          }else {
            let temp = {
              "product": displayOrder[i],
              "is_active": false,
              "subject": "english",
              "expiry_date": 0
            }
            subData.push(temp);
          }
        }
      }
      return subData
    }
  }

  // data conversion for the display
  convertData(data) {
    let result = [];
    data.forEach((data) => {
      let temp = {};
      temp['email'] = data.email;
      temp['name'] = data.name;
      temp['role'] = data.role;
      temp['register_time_stamp'] = moment(data.register_time_stamp).format("DD/MM/YYYY");
      for (let j = 0; j < data.subscription.length; j++) {
        for (let i = 0; i < displayOrder.length; i++) {
          if (data.subscription[j].product === displayOrder[i]) {
            temp[displayOrder[i] + 'Expiry'] = this.timeConverter(data.subscription[j].expiry_date);
            temp[displayOrder[i] + 'Status'] = data.subscription[j].is_active === false ? "In-Active" : "Active";
          }
        }
      }
      result.push(temp);
    })
    this.setState({ showData: result })
  }
  timeConverter(UNIX_timestamp) {
    const a = new Date(UNIX_timestamp * 1000);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const time = date + "/" + month + "/" + year;
    if (time === "1/Jan/1970") {
      return "-"
    } else {
      return time;
    }
  }

  handleExpired() {
    const me = this;
    me.setState({ showExpired: !me.state.showExpired }, function () {
      const expired = me.state.showExpired;
      const filterData = [];
      me.state.taraData.forEach((item) => {
        if (expired) {
          let currentDate = new Date()
          for (var i = 0; i < item.subscription.length; i++) {
            if (currentDate > new Date(me.timeConverter(item.subscription[i].expiry_date))) {
              filterData.push(item);
              break;
            }
          }
        } else {
          filterData.push(item)
        }
      })
      me.convertData(filterData)
    })
  }
  filterData() {
    const me = this;
    let filterData = [];
    const start = moment(me.state.filterStartDate);
    const end = moment(me.state.filterEndDate);
    me.state.taraData.forEach((item) => {
      const actual = moment(item.register_time_stamp);
      if (moment(actual).isBetween(start, end)) {
        filterData.push(item)
      }
    });
    me.convertData(filterData)

  }
  render() {
    const columns = [
      { title: "Account Email", field: "email", sorting: true },
      { title: "Name", field: "name" },
      { title: "Type", field: "role" },
      { title: "Registered Date", field: "register_time_stamp" },
      { title: "Plus Status", field: "plusStatus", export: false },
      { title: "Plus", field: "plusExpiry" },
      { title: "Star Status", field: "starStatus", export: false },
      { title: "Star", field: "starExpiry" },
      { title: "Champ Status", field: "champStatus", export: false },
      { title: "Champ", field: "champExpiry" },
      { title: "Expert Status", field: "expertStatus", export: false },
      { title: "Expert", field: "expertExpiry" },
      { title: "Master Status", field: "masterStatus", export: false },
      { title: "Master", field: "masterExpiry" },
      { title: "Neo Status", field: "neoStatus", export: false },
      { title: "Neo", field: "neoExpiry" },
      { title: "Pro Status", field: "proStatus", export: false },
      { title: "Pro", field: "proExpiry" },
      { title: "Ace Status", field: "aceStatus", export: false },
      { title: "Ace", field: "aceExpiry" },
      { title: "Fifth Status", field: "5thStatus", export: false },
      { title: "Fifth", field: "5thExpiry" },
    ];
    return (
      <div>
        <Grid container>
          <Grid item lg={5}></Grid>
          <Grid item lg={5} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" style={{ fontWeight: "bold", marginRight: "6px" }} >Registered Between</Typography>
            <CustomDatePicker
              dateFormat="dd/MM/yyyy"
              onChange={(value) => {
                if (value[0] === null && value[1] === null) {
                  this.convertData(this.state.taraData)
                }
                this.setState({ filterStartDate: value[0], filterEndDate: value[1] }, function () {
                  if (value[1] !== null) {
                    this.filterData()
                  }
                });
              }}
              selectsRange={true}
              isClearable={true}
              startDate={this.state.filterStartDate}
              endDate={this.state.filterEndDate}
              wrapperClassName="dateWrapper"
              className="form-control"
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [5, 10],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </Grid>
          <Grid item lg={2} style={{ textAlign: "right" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.showExpired}
                  onChange={() => { this.handleExpired() }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={<Typography variant="body1" style={{ fontWeight: "bold" }}>Only Expired</Typography>}
              labelPlacement="start"
              className="formControlLabel"
            />
          </Grid>
        </Grid>
        <MaterialTable
          title="Subscribed Accounts"
          icons={tableIcons}
          columns={columns}
          data={this.state.showData}
          components={{
            Header: props => {
              return (
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell rowSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Account Email</TableCell>
                    <TableCell rowSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell rowSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell rowSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Registered Date</TableCell>
                    <TableCell colSpan={12} scope="col" align="center" style={{ fontWeight: "bold" }}>Student Products</TableCell>
                    <TableCell colSpan={4} scope="col" align="center" style={{ fontWeight: "bold" }}>Teacher Products</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>EVS</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Plus</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Star</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Champ</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Expert</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Master</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Neo</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Pro</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Ace</TableCell>
                    <TableCell colSpan={2} scope="col" align="center" style={{ fontWeight: "bold" }}>Fifth</TableCell>
                  </TableRow>
                </TableHead>
              );
            },
            Row: ({ data }) => {
              return (
                <TableRow onClick={() => {
                  let result = this.state.currentData.filter(item => {
                    return item.email === data.email && moment(item.register_time_stamp).format("DD/MM/YYYY") === data.register_time_stamp
                  });
                  this.setState({ editItem: result[0] ,openEditModal:true})
                }} className="table-row">
                  <TableCell>{data.email}</TableCell>
                  <TableCell align="center">{data.name}</TableCell>
                  <TableCell align="center">{data.role}</TableCell>
                  <TableCell align="center">{data.register_time_stamp}</TableCell>
                  <TableCell align="center">{data.plusStatus}</TableCell>
                  <TableCell align="center">{data.plusExpiry}</TableCell>
                  <TableCell align="center">{data.starStatus}</TableCell>
                  <TableCell align="center">{data.starExpiry}</TableCell>
                  <TableCell align="center">{data.champStatus}</TableCell>
                  <TableCell align="center">{data.champExpiry}</TableCell>
                  <TableCell align="center">{data.expertStatus}</TableCell>
                  <TableCell align="center">{data.expertExpiry}</TableCell>
                  <TableCell align="center">{data.masterStatus}</TableCell>
                  <TableCell align="center">{data.masterExpiry}</TableCell>
                  <TableCell align="center">{data.neoStatus}</TableCell>
                  <TableCell align="center">{data.neoExpiry}</TableCell>
                  <TableCell align="center">{data.proStatus}</TableCell>
                  <TableCell align="center">{data.proExpiry}</TableCell>
                  <TableCell align="center">{data.aceStatus}</TableCell>
                  <TableCell align="center">{data.aceExpiry}</TableCell>
                  <TableCell align="center">{data['5thStatus']}</TableCell>
                  <TableCell align="center">{data['5thExpiry']}</TableCell>
                </TableRow>
              );
            },
          }}
          options={{
            exportButton: true,
            exportAllData: true
          }}
        />
        <LoaderComponent open={this.state.loading} />
        {this.state.editItem && <ModalComponent data={{
          open: this.state.openEditModal,
          title: this.state.editItem.name,
          body:<TaraTableEdit ref={this.editComponent} 
            editItem={this.state.editItem} 
            onUpdate={()=>this.fetchData()} 
            isEdit={(value)=>{
              this.setState({isEdit:value})
            }}
            showConfirm={(val)=>{
              this.setState({ confirmModal: val })
            }}
            onCancel={()=>{
              if(this.state.isEdit){
                this.setState({ confirmModal: true });
              }else{
                this.editComponent.current.handleCancel();
              }
            }}
          />,
          saveText: "Close",
          modalDialogClass: "modal-dialog-centered",
          onSave: () => {
            if(this.state.isEdit){
              this.setState({ confirmModal: true });
            }else{
              this.setState({ openEditModal: false });
            }
          },
          showCancel: false,
          classes:{paper:"edit-modal"}
        }} />}
         {/* confirmation modal if user cancel the updates */}
         <ModalComponent data={{
          open:this.state.confirmModal,
          title:"Confirmation",
          saveText:"Discard",
          body:"Are you sure you want to discard the changes .",
          onClose:() => {
            this.setState({ confirmModal: false })
          },
          onSave:() => {
            this.editComponent.current.handleCancel();
            this.setState({ isEdit: false,confirmModal: false });
          },
          showCancel:true
        }}
        />
      </div>
    );
  }
}

export default withRouter(TaraTable);
