import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Home from "./TaraTable";
import AppBar from "./AppBar";
import ChangePassword from "./ChangePassword";
import {getUser} from "../../Service/auth";

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:''
    };
  }
  componentDidMount() {
    var user = sessionStorage.getItem("authToken");
    if (user === undefined || user === null) {
      this.props.history.push("/");
      return;
    }else{
      getUser().then((res)=>{
        console.log("res",res)
        this.setState({email:res.email})
      }).catch((er)=>{
        console.log(er)
      })
    }
  }

  render() {
    return (
      <div>
        <AppBar email={this.state.email}/>
        <Switch>
          <Route exact path={`${this.props.match.path}`} component={Home} />
          <Route path={`${this.props.match.path}/change/password`} component={ChangePassword} />
          <Redirect
            path={`${this.props.match.path}`}
            to={`${this.props.match.path}`}
          />
        </Switch>
      </div>
    );
  }
}

export default Application;
