import React from "react";
import { withRouter } from "react-router-dom";
import ProfileDropDown from "./profileDropDown";

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    var me = this;
    return (
      <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <h6 className="navbar-brand">
            Tara User Subscription Manager
            {"(" + process.env.REACT_APP_DATABASE + ")"}
          </h6>
          <form className="d-flex">
            <ProfileDropDown email={me.props.email}/>
          </form>
        </div>
      </nav>
    );
  }
}

export default withRouter(AppBar);
