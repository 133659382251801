import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Login from "./Component/Login";
import Home from "./Component/Application";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path={"/login"} component={Login} />
        <Route path={"/home"} component={Home} />
        <Redirect exact path={"/"} to={`/login`} />
      </Switch>
    );
  }
}

export default App;
