import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getProps = this.getProps.bind(this);
  }
  getProps() {
    var myProps = this.props.data;
    var data = {
      open: myProps.open === undefined ? false : myProps.open,
      title: myProps.title === undefined ? "Modal title" : myProps.title,
      body: myProps.body === undefined ? "Modal Body" : myProps.body,
      onSave: myProps.onSave === undefined ? () => null : myProps.onSave,
      onClose: myProps.onClose === undefined ? () => null : myProps.onClose,
      saveText: myProps.saveText === undefined ? "Update" : myProps.saveText,
      showCancel: myProps.showCancel === undefined ? true : myProps.showCancel,
      className: myProps.className === undefined ? "" : myProps.className,
      dialogStyle: myProps.dialogStyle === undefined ? {} : myProps.dialogStyle,
      classes: myProps.classes === undefined ? {} : myProps.classes,

    };
    return data;
  }
  render() {
    let props = this.getProps();
    return (<Dialog  open={props.open} onClose={() => { props.onClose() }} aria-labelledby="form-dialog-title"
      style={props.dialogStyle} className={props.className} classes={props.classes}>
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>
        {props.body}
      </DialogContent>
      <DialogActions>
        {props.showCancel && <Button onClick={() => { props.onClose() }} color="primary" variant="contained">
          Cancel
        </Button>}
        <Button onClick={() => { props.onSave() }} color="primary" variant="contained">
          {props.saveText}
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

ModalComponent.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.symbol,
      PropTypes.element,
    ]),
    onSave: PropTypes.func,
    title: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    saveText: PropTypes.string,
    showCancel: PropTypes.bool,
    dialogStyle: PropTypes.object,
    className: PropTypes.string,
    classes:PropTypes.object,
  }),
};

ModalComponent.defaultProps = {
  data: {
    body: "Modal Body",
    onSave: () => null,
    title: "Modal title",
    onClose: () => null,
    open: false,
    saveText: "Update",
    showCancel: true,
    dialogStyle: {},
    className:"",
    classes:{},

  },
};

export default ModalComponent;