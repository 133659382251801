import React from "react";
import CustomDatePicker from "../../Common/DatePicker";
import "./style.css";
import { updateData } from "../../../Service/tara";
import { dbOrder } from "../../../Util/productSubs";
import * as uuid from "uuid";
import ModalComponent from "../../Common/Modal";
import { Grid, Divider, Paper, Typography, Card, CardContent } from "@material-ui/core";
import LoaderComponent from "../../Common/Loader";
import SnackBarComponent from "../../Common/SnackBar";
import { JsonFormatter } from 'react-json-formatter'
// import {Link as RouterLink} from "react-router-dom"

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userObject: null,
      userEditObject: null,
      error: null,
      addModalOpen: false,
      selectedPerson: null,
      updateModalInput: "",
      updateModalInput_error: "",
      selectIndex: null,
      statusUpdateModal: false,
      alexaIdUpdateModal: false,
      loading: false,
      updated: false,
      openPersistentModal: false,
      selectPersistent: "",
      confirmModal:false,
    };
    this.setInitialData = this.setInitialData.bind(this);
    this.setIsEditProp  = this.setIsEditProp.bind(this);
  }
  componentDidMount() {
    var auth = sessionStorage.getItem("authToken");
    if (auth === undefined || auth === null) {
      this.props.history.push("/");
      return;
    }
    // let userData = this.props.location.state;
    let userData = this.props.editItem;
    this.setInitialData(userData)
  }

  componentDidUpdate(prevProps) {
    if (this.props.editItem.email !== prevProps.editItem.email) {
      let userData = this.props.editItem;
      this.setInitialData(userData)
    }
  }

  setInitialData(data) {
    let userData = data;
    if (userData.person === undefined) {
      userData["person"] = [];
    } else {
      userData.person.forEach((data) => {
        data["person_name_error"] = "";
        data["person_role_error"] = "";
        data["person_pin_error"] = "";
        if (data.status === undefined) {
          data["status"] = true;
        }
        return data;
      });
    }
    this.setState({
      userObject: userData,
      userEditObject: userData,
    });
  }
  // Function for UNIX time stamp
  timeConverter(UNIX_timestamp) {
    const a = new Date(UNIX_timestamp * 1000);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const time = date + "/" + month + "/" + year;
    return time;
  }

  // Function for updating data
  handleSave() {
    const me = this;
    const userEditObject = { ...me.state.userEditObject };
    const personsList = [...userEditObject.person]
    if (
      userEditObject.name === undefined ||
      userEditObject.name === null ||
      userEditObject.name.length === 0
    ) {
      userEditObject.name_error = true;
      me.setState({ userEditObject });
      return;
    }
    if (
      userEditObject.role === undefined ||
      userEditObject.role === null ||
      userEditObject.role.length === 0
    ) {
      userEditObject.role_error = true;
      me.setState({ userEditObject });
      return;
    }
    for (let i = 0; i < personsList.length; i++) {
      let perosnObject = { ...personsList[i] };

      if (
        perosnObject.person_name === null ||
        perosnObject.person_name === undefined ||
        perosnObject.person_name.length === 0
      ) {
        perosnObject.person_name_error = "Please enter name";
        personsList[i] = perosnObject;
        userEditObject.person = personsList;
        me.setState({ userEditObject });
        return;
      }
      if (
        perosnObject.person_role === null ||
        perosnObject.person_role === undefined ||
        perosnObject.person_role.length === 0
      ) {
        perosnObject.person_role_error = "Please select role";
        personsList[i] = perosnObject;
        userEditObject.person = personsList;
        me.setState({ userEditObject });
        return;
      }
      if (
        perosnObject.person_pin === null ||
        perosnObject.person_pin === undefined
      ) {
        perosnObject.person_pin_error = "Please enter pin";
        personsList[i] = perosnObject;
        userEditObject.person = personsList;
        me.setState({ userEditObject });
        return;
      }
      if (String(perosnObject.person_pin).length < 4) {
        perosnObject.person_pin_error = "Please enter 4 digit pin";
        personsList[i] = perosnObject;
        userEditObject.person = personsList;
        me.setState({ userEditObject });
        return;
      }
      if (perosnObject.person_pin_error !== undefined && perosnObject.person_pin_error.length !== 0) {
        return;
      }
      if (
        perosnObject.person_uuid === null ||
        perosnObject.person_uuid === undefined ||
        perosnObject.person_uuid.length === 0
      ) {
        perosnObject.person_uuid = uuid.v1();
        personsList[i] = perosnObject;
        userEditObject.person = personsList;
        me.setState({ userEditObject });
      }
    }
    let userSubscription = [...userEditObject.subscription];
    // reversing the sorted data(same as backend)
    userSubscription.sort(function (a, b) {
      return (
        dbOrder.indexOf(a.product) -
        dbOrder.indexOf(b.product)
      );
    });
    // let persons = [...userEditObject.person];

    let persons = personsList.map(function (v) {
      delete v.person_name_error;
      delete v.person_role_error;
      delete v.person_pin_error;
      return v;
    });
    const data = {
      UserName: me.state.userEditObject.email,
      Subscription: userSubscription,
      Name: me.state.userEditObject.name,
      Role: me.state.userEditObject.role,
      Person: persons,
    };
    me.setState({ loading: true })
    updateData(JSON.stringify(data))
      .then((res) => {
        var userObject = me.state.userEditObject;
        userObject.is_edit = false;
        me.state.userObject = userObject;
        me.setState({
          userObject: me.state.userObject,
          loading: false,
          updated: true
        });
        this.props.onUpdate();
        this.setIsEditProp(false)
      })
      .catch((er) => {
        this.setState({ error: er, loading: false });
      });
  }

  // Function for product status button
  handleButton(i, value) {
    let me = this;
    let userEditObject = { ...me.state.userEditObject };
    const userObject = { ...userEditObject };
    const userSubscriptions = [...userObject.subscription];
    const subscription = {
      ...userSubscriptions[i],
    };
    subscription.is_active = value;

    if (value === true) {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      let unixDate = date.getTime() / 1000;
      subscription.expiry_date = unixDate;
    } else {
      let date = new Date();
      let unixDate = date.getTime() / 1000;
      subscription.expiry_date = unixDate;
    }
    let currentUnixDate = new Date().getTime() / 1000;
    let license = { ...subscription.license };
    let licenseCreate = { ...license.create };
    let licenseUpdate = { ...license.update };
    if (licenseCreate.is_created === false) {
      licenseCreate.date = currentUnixDate;
      licenseCreate.author = sessionStorage.getItem("user_email");
      licenseCreate.is_created = true;
    } else {
      licenseUpdate.date = currentUnixDate;
      licenseUpdate.author = sessionStorage.getItem("user_email");
    }
    license.create = licenseCreate;
    license.update = licenseUpdate;
    subscription.license = license;
    userSubscriptions[i] = subscription;
    userObject.subscription = userSubscriptions;
    userEditObject = userObject;
    me.setState({ userEditObject});
    this.setIsEditProp(true)
  }

  // Function for product expiry date inputs
  handleDateInput(i, value) {
    let me = this;
    let userEditObject = { ...me.state.userEditObject };
    const userObject = { ...userEditObject };
    const userSubscriptions = [...userObject.subscription];
    const subscription = {
      ...userSubscriptions[i],
    };
    subscription.expiry_date = value;
    let currentUnixDate = new Date().getTime() / 1000;
    let license = { ...subscription.license };
    let licenseCreate = { ...license.create };
    let licenseUpdate = { ...license.update };
    if (licenseCreate.is_created === false) {
      licenseCreate.date = currentUnixDate;
      licenseCreate.author = sessionStorage.getItem("user_email");
      licenseCreate.is_created = true;
    } else {
      licenseUpdate.date = currentUnixDate;
      licenseUpdate.author = sessionStorage.getItem("user_email");
    }
    license.create = licenseCreate;
    license.update = licenseUpdate;
    subscription.license = license;
    userSubscriptions[i] = subscription;
    userObject.subscription = userSubscriptions;
    userEditObject = userObject;
    me.setState({ userEditObject });
    this.setIsEditProp(true)
  }

  // Function to handle the cancel
  handleCancel() {
    const me = this;
    me.setState({
      userEditObject: { ...me.state.userObject },
      error: null
    },function() {
      this.setIsEditProp(false)
    });
  }
  // Function to add a new profile
  addPersonProfile() {
    const me = this;
    let profiles = [...me.state.userEditObject.person];
    const addProfile = {
      person_id: "",
      person_name: "",
      person_role: "",
      person_pin: "",
      person_uuid: uuid.v1(),
      status: true
    }
    profiles.push(addProfile);
    me.state.userEditObject.person = profiles;
    me.setState({ userEditObject: me.state.userEditObject })
  }
  handleVoiceStatus(index) {
    let me = this;
    let userEditObject = { ...me.state.userEditObject };
    const userObject = { ...userEditObject };
    const personsList = [...userObject.person];
    const person = {
      ...personsList[index],
    };
    me.setState({ selectedPerson: person, selectIndex: index, statusUpdateModal: true });
  }
  setIsEditProp(val){
   this.props.isEdit(val) 
  }
  render() {
    let userObject = this.state.userObject;
    let userEditObject = { ...this.state.userEditObject };
    if (userObject === null) {
      return null;
    }
    return (
      <div className="container-fluid">
        <Paper className="shadow m-auto p-3" style={{ backgroundColor: "#f5f5f5" }}>
          {this.state.error !== null && (
            <div className="alert alert-danger alert-dismissible" role="alert">
              Something went wrong
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  this.setState({ error: null });
                }}
              ></button>
            </div>
          )}
          {userEditObject.is_edit === false && (
            <div className="text-right mb-2">
              <button
                id="btnGroupDrop1"
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  userEditObject.is_edit = true;
                  this.setState({ userEditObject: userEditObject });
                }}
              >
                Edit
                <i className="fa fa-pencil ml-2" aria-hidden="true"></i>
              </button>
            </div>
          )}
          {userEditObject.is_edit === true && (
            <React.Fragment>
              <div className="text-right mb-2">
                <button
                  id="cancel"
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    if(this.state.isEdit){
                      this.setState({confirmModal:true})
                    }else{
                      this.props.onCancel();
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  id="update"
                  type="button"
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    this.handleSave();
                  }}
                >
                  Update
                </button>
              </div>
            </React.Fragment>
          )}
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <div >
                <div>
                  <Typography variant="h5" color="primary">Account Details</Typography>
                </div>
                <Divider style={{ border: "1px solid black", margin: "0.5%" }} />
                <Card>
                  <CardContent>
                    <div className="row mb-2 mt-2">
                      <div className="col-6">
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Account Email</Typography>
                      </div>
                      <div className="col-6">
                        <Typography variant="body1">{userObject.email}</Typography>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6">
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Account Name</Typography>
                      </div>
                      {/* ---------when name is in view mode--------- */}
                      {userEditObject.is_edit === false && (
                        <div className="col-6">
                          <Typography variant="body1">{userObject.name}</Typography>
                        </div>
                      )}
                      {/* ---------when name is in editing mode--------- */}
                      {userEditObject.is_edit === true && (
                        <div className="col-6">
                          <input
                            id="name"
                            className="form-control"
                            type="text"
                            pattern="[A-Za-z]"
                            // pattern="[A-Za-z]"
                            maxLength="60"
                            value={
                              userEditObject.name !== undefined &&
                                userEditObject.name !== null &&
                                userEditObject.name.length !== 0
                                ? userEditObject.name
                                : ""
                            }
                            onChange={(event) => {
                              let textInput = event.target.value;
                              textInput = textInput.replace(/[^A-Za-z ]*$/gm, "");
                              userEditObject.name = textInput;
                              userEditObject.name_error = "";
                              this.setState({ userEditObject: userEditObject });
                              this.setIsEditProp(true)
                            }}
                          />
                          {userEditObject.name_error && (
                            <h6 className="text-danger">Please enter name</h6>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row mb-2">
                      <div className="col-6 ">
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>Account Type</Typography>

                      </div>
                      {/* ---------when role is in view mode--------- */}
                      {userEditObject.is_edit === false && (
                        <div className="col-6 ">
                          <Typography variant="body1">{userObject.role}</Typography>
                        </div>
                      )}
                      {/* ---------when role is in editing mode--------- */}
                      {userEditObject.is_edit === true && (
                        <div className="col-6">
                          <select
                            className="form-select"
                            aria-label="select role"
                            id="role"
                            value={
                              userEditObject.role !== undefined &&
                                userEditObject.role !== null &&
                                userEditObject.role.length !== 0
                                ? userEditObject.role
                                : ""
                            }
                            onChange={(event) => {
                              userEditObject.role = event.target.value;
                              userEditObject.role_error = "";
                              this.setState({ userEditObject: userEditObject});
                              this.setIsEditProp(true)
                            }}
                          >
                            <option value="" disabled>
                              Select Type
                            </option>
                            <option value="Admin">Admin</option>
                            <option value="Institution">Institution</option>
                            <option value="Individual">Individual</option>
                          </select>
                          {userEditObject.role_error && (
                            <h6 className="text-danger">Please select type</h6>
                          )}
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Grid>
            {/* ----------Subscription Table----------- */}
            <Grid item lg={6}>
              <div >
                <div>
                  <Typography variant="h5" color="primary">Account Subscriptions</Typography>
                </div>
                <Divider style={{ border: "1px solid black", margin: "0.5%" }} />
                <Card>
                  <CardContent>
                    <div className="col-12 table-responsive">
                      <table className="table table-striped caption-top subsTable">
                        <thead className="tableHead">
                          <tr>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>Product</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>Subscription Status</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>Expiry</Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* ----------when subscription data is in view mode----------- */}
                          {userEditObject.is_edit === false &&
                            userObject.subscription.map((item, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>{item.product}</td>
                                    <td>
                                      {item.is_active === true ? "Active" : "In-Active"}
                                    </td>
                                    <td>{this.timeConverter(item.expiry_date)}</td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          {/* ----------when subscription data is in edit mode----------- */}
                          {userEditObject.is_edit === true &&
                            userEditObject.subscription.map((item, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>{item.product}</td>
                                    <td className="align-middle">
                                      {item.is_active === false && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.handleButton(i, true);
                                          }}
                                          className="btn btn-secondary "
                                          style={{ padding: "6px", width: "50%" }}
                                        >
                                          In-Active
                                        </button>
                                      )}
                                      {item.is_active === true && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.handleButton(i, false);
                                          }}
                                          className=" btn btn-primary "
                                          style={{ padding: "6px", width: "50%" }}
                                        >
                                          Active
                                        </button>
                                      )}
                                    </td>
                                    <td
                                      className="align-middle"
                                      style={{ width: "30%" }}
                                    >
                                      <CustomDatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                          new Date(this.timeConverter(item.expiry_date))
                                        }
                                        onChange={(value) => {
                                          var unix_date = value.getTime() / 1000;
                                          this.handleDateInput(i, unix_date);
                                        }}
                                        wrapperClassName="dateWrapper1"
                                        className="form-control"
                                        popperModifiers={{
                                          offset: {
                                            enabled: true,
                                            offset: "5px, 10px",
                                          },
                                          preventOverflow: {
                                            enabled: true,
                                            escapeWithReference: false,
                                            boundariesElement: "viewport",
                                          },
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                      />
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>

              </div>
            </Grid>
            <Grid item lg={12}>
              {/* ----------Voice Profile Table----------- */}
              <div>
                <div className="d-flex">
                  <div>
                    <Typography variant="h5" color="primary">Account Users</Typography>
                  </div>
                  {userEditObject.is_edit === true && (<div className="ml-auto">
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.addPersonProfile();
                      }}
                    >
                      Add
                      <i className="fa fa-plus ml-2" aria-hidden="true"></i>
                    </button>
                  </div>)}
                </div>
                <Divider style={{ border: "1px solid black", margin: "0.5%" }} />
                <Card>
                  <CardContent>
                    <div className="col-12 table-responsive">
                      <table className="table table-striped">
                        <thead className="tableHead">
                          <tr>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>Alexa Profile Id</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>User Unique Id</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>User Name</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>User Role</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>User's Tara PIN</Typography>
                            </th>
                            <th scope="col">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>Status</Typography>
                            </th>
                            <th scope="col" className="text-center">
                              <Typography variant="body1" style={{ fontWeight: "bold" }}>Persistent</Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userObject.person.length === 0 && (
                            <tr>
                              <td className="text-center" colSpan="6">
                                No Data
                              </td>
                            </tr>
                          )}
                          {/* ----------when data is in view mode----------- */}
                          {userEditObject.is_edit === false &&
                            userObject.person.map((item, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td className="personID">{item.person_id}</td>
                                    <td className="personID">{item.person_uuid !== undefined ? item.person_uuid : "-"}</td>
                                    <td>{item.person_name}</td>
                                    <td>
                                      {item.person_role !== undefined &&
                                        item.person_role !== null &&
                                        item.person_role.length !== 0
                                        ? item.person_role
                                        : ""}
                                    </td>
                                    <td>{item.person_pin}</td>
                                    <td>
                                      {item.status === true ? "Active" : "In-Active"}
                                    </td>
                                    <td className="text-center">
                                      <i
                                        className="fa fa-eye ml-2"
                                        onClick={() => {
                                          let data = {
                                            name: item.person_name,
                                          };
                                          if (item.mapping !== undefined) {
                                            data["persistent"] = JSON.stringify(item.mapping);
                                          } else {
                                            data["persistent"] = JSON.stringify("Don't have any persistent");
                                          }
                                          this.setState({ openPersistentModal: true, selectPersistent: data });
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          {/* ----------when data is in edit mode----------- */}
                          {userEditObject.is_edit === true &&
                            userEditObject.person.map((item, i) => {
                              return (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td className="personID alexaId" onClick={() => {
                                      this.setState({ alexaIdUpdateModal: true, selectedPerson: item, selectIndex: i })
                                    }}
                                    >{item.person_id}</td>
                                    <td className="personID">{item.person_uuid !== undefined ? item.person_uuid : "-"}</td>
                                    <td>
                                      <input
                                        id={i + "name"}
                                        className="form-control"
                                        type="text"
                                        maxLength="15"
                                        value={
                                          item.person_name !== undefined &&
                                            item.person_name !== null &&
                                            item.person_name.length !== 0
                                            ? item.person_name
                                            : ""
                                        }
                                        onChange={(event) => {
                                          const me = this;
                                          let textInput = event.target.value;
                                          textInput = textInput.replace(
                                            /[^A-Za-z ]*$/gm,
                                            ""
                                          );
                                          let userEditObject = { ...me.state.userEditObject };
                                          const userObject = { ...userEditObject };
                                          const personsList = [...userObject.person];
                                          const personObject = {
                                            ...personsList[i],
                                          };
                                          personObject.person_name = textInput;
                                          personObject.person_name_error = "";

                                          personsList[i] = personObject;
                                          userObject.person = personsList;
                                          userEditObject = userObject;
                                          me.setState({ userEditObject});
                                          this.setIsEditProp(true)
                                        }}
                                      />
                                      {item.person_name_error && (
                                        <h6 className="text-danger">
                                          Please enter name
                                        </h6>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        className="form-select"
                                        aria-label="select role"
                                        id="role"
                                        value={
                                          item.person_role !== undefined &&
                                            item.person_role !== null &&
                                            item.person_role.length !== 0
                                            ? item.person_role
                                            : ""
                                        }
                                        onChange={(event) => {
                                          const me = this;
                                          let textInput = event.target.value;
                                          textInput = textInput.replace(
                                            /[^A-Za-z ]*$/gm,
                                            ""
                                          );
                                          let userEditObject = { ...me.state.userEditObject };
                                          const userObject = { ...userEditObject };
                                          const personsList = [...userObject.person];
                                          const personObject = {
                                            ...personsList[i],
                                          };
                                          personObject.person_role = textInput;
                                          personObject.person_role_error = "";

                                          personsList[i] = personObject;
                                          userObject.person = personsList;
                                          userEditObject = userObject;
                                          me.setState({ userEditObject });
                                          this.setIsEditProp(true)
                                        }}
                                      >
                                        <option value="">Select Role</option>
                                        <option value="Teacher">Teacher</option>
                                        <option value="Student">Student</option>
                                        <option value="Individual">Individual</option>
                                      </select>
                                      {item.person_role_error && (
                                        <h6 className="text-danger">
                                          Please select role
                                        </h6>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        id={i + "pin"}
                                        className="form-control"
                                        type="number"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                        value={
                                          item.person_pin !== undefined &&
                                            item.person_pin !== null
                                            ? item.person_pin
                                            : ""
                                        }
                                        onChange={(event) => {
                                          const me = this;
                                          let userEditObject = { ...me.state.userEditObject };
                                          const userObject = { ...userEditObject };
                                          const personsList = [...userObject.person];
                                          const personObject = {
                                            ...personsList[i],
                                          };
                                          if (Number(event.target.value) === 9999 && item.person_id !== "default person id") {
                                            personObject.person_pin_error = "9999 is invalid pin";
                                            personsList[i] = personObject;
                                            userObject.person = personsList;
                                            userEditObject = userObject;
                                            me.setState({ userEditObject });
                                            return
                                          }
                                          // condition to check the unique pin 
                                          if (personsList.some((value, index) => {
                                            if (index !== i) {
                                              return value.person_pin === Number(event.target.value)
                                            }
                                            return false
                                          })
                                          ) {
                                            personObject.person_pin = Number(event.target.value);
                                            personObject.person_pin_error = "Please enter unique pin";
                                            personsList[i] = personObject;
                                            userObject.person = personsList;
                                            userEditObject = userObject;
                                            me.setState({ userEditObject });
                                            this.setIsEditProp(true)
                                            return;
                                          } else {
                                            if (event.target.value.length !== 0) {
                                              personObject.person_pin = Number(event.target.value);
                                            } else {
                                              personObject.person_pin = event.target.value;
                                            }
                                            personObject.person_pin_error = "";
                                            personsList[i] = personObject;
                                            userObject.person = personsList;
                                            userEditObject = userObject;
                                            me.setState({ userEditObject });
                                            this.setIsEditProp(true)

                                          }
                                        }}
                                      />
                                      {item.person_pin_error && (
                                        <h6 className="text-danger">
                                          {item.person_pin_error}
                                        </h6>
                                      )}
                                    </td>
                                    <td className="align-middle">
                                      {item.status === false && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.handleVoiceStatus(i, true);
                                          }}
                                          className="btn btn-secondary "
                                          style={{ padding: "6px", width: "100%" }}
                                        >
                                          In-Active
                                        </button>
                                      )}
                                      {item.status === true && (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.handleVoiceStatus(i, false);
                                          }}
                                          className=" btn btn-primary "
                                          style={{ padding: "6px", width: "100%" }}
                                        >
                                          Active
                                        </button>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      <i
                                        className="fa fa-eye ml-2"
                                        onClick={() => {
                                          let data = {
                                            name: item.person_name,
                                          };
                                          if (item.mapping !== undefined) {
                                            data["persistent"] = JSON.stringify(item.mapping);
                                          } else {
                                            data["persistent"] = JSON.stringify("Don't have any persistent");
                                          }
                                          this.setState({ openPersistentModal: true, selectPersistent: data });
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Paper>

        {/* for activating and deactivating the user */}
        <ModalComponent data={{
          open: this.state.statusUpdateModal,
          title: "Deactivate/Reactivate User?",
          body:
            <div>
              <h6>Are you sure you want to {this.state.selectedPerson !== null &&
                this.state.selectedPerson.status === true ? `deactivate ` : `reactivate `}
                <b>{this.state.selectedPerson !== null && this.state.selectedPerson.person_name}</b>  If yes, please enter user's name here.</h6>
              <input
                type="text"
                className="form-control"
                id="profileName"
                value={this.state.updateModalInput}
                onChange={(event) => {
                  this.setState({
                    updateModalInput: event.target.value,
                    updateModalInput_error: "",
                  });
                }}
              />
              {this.state.updateModalInput_error.length !== 0 && (
                <h6 className="error-text">{this.state.updateModalInput_error}</h6>
              )}
            </div>,
          saveText: "Update",
          modalDialogClass: "modal-dialog-centered",
          onSave: () => {
            const updateModalInput = this.state.updateModalInput;
            if (updateModalInput == null || updateModalInput.trim().length === 0) {
              this.setState({ updateModalInput_error: "Must not be empty." });
              return;
            }
            if (updateModalInput.trim() !== this.state.selectedPerson.person_name.trim()) {
              this.setState({ updateModalInput_error: "user name is incorrect" });
              return;
            }
            let userEditObject = { ...this.state.userEditObject };
            const userObject = { ...userEditObject };
            const personsList = [...userObject.person];
            const personObject = {
              ...personsList[this.state.selectIndex],
            };
            personObject.status = !personObject.status;
            personsList[this.state.selectIndex] = personObject;
            userObject.person = personsList;
            userEditObject = userObject;
            this.setState({ updateModalInput: "", updateModalInput_error: "", userEditObject, statusUpdateModal: false});
            this.setIsEditProp(true)
          },
          onClose: () => {
            this.setState({ updateModalInput: "", updateModalInput_error: "", statusUpdateModal: false })
          }
        }} />

        {/* for deleting the alexa Id */}
        <ModalComponent data={{
          open: this.state.alexaIdUpdateModal,
          title: "Delete Alexa profile Id?",
          body:
            <div>
              <h6>If you would like to delete <b>{this.state.selectedPerson !== null && this.state.selectedPerson.person_name}'s </b>
                Alexa Profile Id, please type <b>delete</b> in space provided below and click Update.</h6>
              <input
                type="text"
                className="form-control"
                id="profileName"
                value={this.state.updateModalInput}
                onChange={(event) => {
                  this.setState({
                    updateModalInput: event.target.value,
                    updateModalInput_error: "",
                  });
                }}
              />
              {this.state.updateModalInput_error.length !== 0 && (
                <h6 className="error-text">{this.state.updateModalInput_error}</h6>
              )}
            </div>,
          saveText: "Update",
          modalDialogClass: "modal-dialog-centered",
          onSave: () => {
            const updateModalInput = this.state.updateModalInput;
            if (updateModalInput == null || updateModalInput.trim().length === 0) {
              this.setState({ updateModalInput_error: "Must not be empty." });
              return;
            }
            if (updateModalInput !== "delete") {
              this.setState({ updateModalInput_error: "Incorrect response" });
              return;
            }
            let userEditObject = { ...this.state.userEditObject };
            const userObject = { ...userEditObject };
            const personsList = [...userObject.person];
            const personObject = {
              ...personsList[this.state.selectIndex],
            };
            personObject.person_id = "";
            personsList[this.state.selectIndex] = personObject;
            userObject.person = personsList;
            userEditObject = userObject;
            this.setState({ updateModalInput: "", updateModalInput_error: "", userEditObject, alexaIdUpdateModal: false });
          },
          onClose: () => {
            this.setState({ updateModalInput: "", updateModalInput_error: "", alexaIdUpdateModal: false })
          }
        }} />

        {/* for presistence */}
        <ModalComponent data={{
          open: this.state.openPersistentModal,
          title: this.state.selectPersistent.name,
          body:
            <div>
              <JsonFormatter json={this.state.selectPersistent.persistent} tabWith='4' JsonStyle={{
                propertyStyle: { color: 'red' },
                stringStyle: { color: 'green' },
                numberStyle: { color: 'darkorange' }
              }} />
            </div>,
          saveText: "Ok",
          modalDialogClass: "modal-dialog-centered",
          onSave: () => {
            this.setState({ openPersistentModal: false });
          },
          onClose: () => {
            this.setState({ openPersistentModal: false })
          },
          showCancel: false
        }} />
        <LoaderComponent open={this.state.loading} />
        <SnackBarComponent open={this.state.updated} message="Account Updated" onClose={() => { this.setState({ updated: false }) }} />
        
       
      </div>
    );
  }
}

export default Edit;
