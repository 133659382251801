import axios from "axios";
import * as q from "q";
const url = process.env.REACT_APP_URL;
export function getData() {
  var defer = q.defer();
  axios
    .get(url)
    .then(function (response) {
      const response_data = response.data;
      if (response.data !== undefined) {
        defer.resolve(response_data);
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    })
    .catch(function (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.message !== undefined
      ) {
        defer.reject({ error: error.response.data.message });
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    });
  return defer.promise;
}

export function updateData(data) {
  var defer = q.defer();
  axios
    .post(url, data)
    .then(function (response) {
      const response_data = response.data;
      if (response.data !== undefined) {
        defer.resolve(response_data);
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    })
    .catch(function (error) {
      console.log(error);
      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.message !== undefined
      ) {
        defer.reject({ error: error.response.data.message });
      } else {
        defer.reject({ error: "Something went wrong!" });
      }
    });
  return defer.promise;
}
