import React from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
//will need this to check valid email
// import * as Validate from "../../Service/validation";
// import data from "../../Util/temp.json";
import {auth} from "../../Service/auth";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      email: "",
      email_error: "",
      password_error: "",
      error: "",
    };
    this.login = this.login.bind(this);
  }
  componentDidMount() {
    var authToken = sessionStorage.getItem("authToken");
    if (authToken !== undefined && authToken !== null) {
      this.props.history.push("/home");
    }
  }
  login() {
    var me = this;
    me.setState({ error: "", email_error: "", password_error: "" });
    const email = me.state.email;
    if (email == null || email.trim().length === 0) {
      me.setState({ email_error: "Must not be empty." });
      return;
    }
    //..........for future use.......
    // if (!Validate.email(email)) {
    //   this.setState({ email_error: "Provide valid email" });
    //   return;
    // }
    const password = me.state.password;
    if (password == null || password.trim().length === 0) {
      me.setState({ password_error: "Must not be empty." });
      return;
    }
    const postData ={
      email:email,
      password:password
    }
    auth(postData).then((resu)=>{
      this.props.history.push("/home");
    }).catch((er)=>{
      me.setState({ error: er.error });
    })

  }
  render() {
    return (
      <div className="form-signUp">
        <div className="learning">
          <h2>Learning Matters</h2>
        </div>
        <div className="login">
          <h4>Login</h4>
        </div>
        {this.state.error.length !== 0 && (
          <h6 className="error-text">{this.state.error}</h6>
        )}
        <form>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={this.state.email}
              onChange={(event) => {
                this.setState({
                  email: event.target.value,
                  email_error: "",
                  error: "",
                });
              }}
            />
            {this.state.email_error.length !== 0 && (
              <h6 className="error-text">{this.state.email_error}</h6>
            )}

            <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              value={this.state.password}
              onChange={(event) => {
                this.setState({
                  password: event.target.value,
                  password_error: "",
                  error: "",
                });
              }}
            />
            {this.state.password_error.length !== 0 && (
              <h6 className="error-text">{this.state.password_error}</h6>
            )}
          </div>
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.login();
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(Login);
