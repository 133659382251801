import React, { Fragment } from "react";
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const props =   this.props;
        return (<Fragment>
            <DatePicker
                dateFormat={props.dateFormat}
                peekNextMonth={props.peekNextMonth}
                disabled={props.disabled}
                dropdownMode={props.dropdownMode}
                showMonthDropdown={props.showMonthDropdown}
                showYearDropdown={props.showYearDropdown}
                onChange={props.onChange}
                selected={props.selected}
                wrapperClassName={props.wrapperClassName}
                className={props.className}
                popperModifiers={props.popperModifiers}
                minDate={props.minDate}
                selectsRange={props.selectsRange}
                isClearable={props.isClearable}
                startDate={props.startDate}
                endDate={props.endDate}
            />
        </Fragment>
        );
    }
}

CustomDatePicker.defaultProps = {
    dateFormat: "MM/dd/yyyy",
    peekNextMonth: true,
    disabled: false,
    dropdownMode: "scroll",
    showMonthDropdown: false,
    showYearDropdown: false,
    onChange: null,
    // selected: new Date(),
    selectsRange:false,
    isClearable:false,
    startDate:null,
    endDate:null,

};

CustomDatePicker.propTypes = {
    dateFormat: PropTypes.string.isRequired,
    peekNextMonth: PropTypes.bool,
    dropdownMode: PropTypes.oneOf(['select', 'scroll']),
    disabled: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.instanceOf(Date),
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    popperModifiers: PropTypes.array,
    minDate: PropTypes.instanceOf(Date),
    selectsRange:PropTypes.bool,
    isClearable:PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),

};

export default CustomDatePicker;
